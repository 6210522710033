import {
  CourseExamQuestion,
  CourseExamQuestionAnswered,
  CourseModule as CourseModuleState,
  CourseSection,
} from '@/services/interfaces/Course'
import { computed, ComputedRef } from 'vue'
import { CourseModule } from '@/store/modules/course'
import { Lesson, LessonQuestion, CoursePackage, CourseItem } from '@/services/interfaces/Course'
import useCommonMixin from './useCommonMixin'
import { QuizCompletion } from '@/services/interfaces/QuizQuestion'
import BookmarkApi from '@/services/api/BookmarkApi'
import { BookmarksModule } from '@/store/modules/Bookmarks'
import useSegment from '@/composables/useSegment'
import useGamification from '@/composables/useGamification'
import { UserModule } from '@/store/modules/user'
import { Nullable } from '@/services/interfaces/Content'
import { CourseExamPart } from '@/services/interfaces/Course'
import { EditorModule } from '@/store/modules/editor'
import useBreakpoint from '@/composables/useBreakpoint'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'

interface useCourseReturn {
  currentCourseId: ComputedRef<string>
  moduleId: ComputedRef<string>
  lessonId: ComputedRef<string>
  courseModules: ComputedRef<CourseModuleState[] | undefined>
  canBuyCourses: ComputedRef<boolean | undefined>
  currentModule: ComputedRef<
    | CourseModuleState
    | {
        id: string
        lessons: never[]
        orderingNumber: number
        isFree: boolean
        courseGroupId: string | null
        guidedQuiz: QuizCompletion | null
        name: string
        disabled: boolean
      }
  >
  firstCourseModule: ComputedRef<(courseId: string, courseGroupId?: string) => CourseModuleState | null>
  currentModuleLessonsLength: ComputedRef<number>
  currentLesson: ComputedRef<Nullable<Lesson>>
  currentLessonQuestions: ComputedRef<Nullable<LessonQuestion[]>>
  studyingStatus: ComputedRef<string>
  showNextCTA: ComputedRef<(ctaStatus: string) => boolean>
  currentModuleTitle: ComputedRef<string>
  currentMobileModuleTitle: ComputedRef<string>
  currentLessonTitle: ComputedRef<string>
  routeToFirstCourseModule: (courseId: string) => void
  completeALesson: (lessonId: string, completionId?: string) => void
  setLessonHasNote: (lessonId: string) => void
  nextEnabledModule: ComputedRef<(courseId: string, courseGroupId?: string) => CourseModuleState | null>
  nextSiblingModule: ComputedRef<(courseId: string, courseGroupId?: string) => CourseModuleState | null>
  nextLesson: (courseGroupId?: string) => Lesson | undefined
  course: ComputedRef<Nullable<CourseItem>>
  courseBundle: ComputedRef<Nullable<CoursePackage>>
  quizMetaCount: ComputedRef<{ passedQuizCount: number; totalQuizCount: number }>
  isPurchasedCourse: ComputedRef<boolean | undefined>
  isTrialCourse: ComputedRef<boolean | undefined>
  quizId: ComputedRef<string>
  nextModuleIndex: (courseGroupId?: string) => number
  nextCourseModule: (courseGroupId?: string) => CourseModuleState | null
  nextLessonIndex: (courseGroupId?: string) => number
  currentModuleIndex: ComputedRef<number>
  currentLessonIndex: ComputedRef<number>
  fetchCourses: () => void
  completedModules: ComputedRef<(course: CourseItem) => number>
  remainingModules: ComputedRef<number>
  hasPurchasedAtLeastACourse: ComputedRef<boolean>
  courseSectionsAssignedCourses: ComputedRef<CourseItem[] | null>
  inTrialCourses: ComputedRef<CourseItem[]>
  unBookmarkCourseLesson: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => void
  bookmarkCourseLesson: (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => void
  courseLessonIsBookmarked: ComputedRef<(lessonId: string) => boolean>
  courseExamId: ComputedRef<string>
  inCourseExamResultsPage: ComputedRef<boolean>
  isCourseExamPaused: ComputedRef<boolean>
  isCourseExamTimedOut: ComputedRef<boolean>
  currentExamQuestion: ComputedRef<Nullable<CourseExamQuestion>>
  reviewingQuestion: ComputedRef<boolean>
  totalPartQuestions: ComputedRef<(index: number) => number>
  totalExamQuestions: ComputedRef<number>
  currentPartIndex: ComputedRef<number>
  answeredQuestions: ComputedRef<CourseExamQuestionAnswered[]>
  examTimeRemaining: ComputedRef<number | null>
  latestExamCompletionId: ComputedRef<string | null>
  examCompletionIdParam: ComputedRef<string>
  guidedQuizDuration: ComputedRef<(numberOfQuestions: number) => number>
  packageId: ComputedRef<string>
  fetchAllCourseModules: (courseIds: string[]) => void
  showStartCourseForFreeModal: (courseId: string, hideCloseButton?: boolean, callback?: () => void) => void
  fetchingEditorDependencies: ComputedRef<boolean>
  progressPercentage: ComputedRef<(courseId: string, courseGroupId?: string) => number>
  groupCourses: (
    courses: CourseItem[],
    skipPurchases?: boolean,
    skipBundles?: boolean,
    showInProgress?: boolean,
  ) => Promise<{ [key: string]: (CourseItem | CoursePackage)[] }>
  showWebinar: ComputedRef<boolean>
  openWebinarModal: () => void
  courseSections: ComputedRef<CourseSection[] | null>
  onLessonPage: ComputedRef<boolean>
  onModulePage: ComputedRef<boolean>
  onKnowledgeCheckInfo: ComputedRef<boolean>
  openLessonModal: (data: {
    lessonId: string
    moduleId: string
    courseId?: string
    teaserImageUrl?: string
    formatContent?: boolean
    lessonToRender?: Lesson
  }) => void
  fetchGroupCourses: () => void
  courseGroupIds: ComputedRef<(courseId: string) => string[]>
  fetchingCourseInfo: ComputedRef<boolean>
  productPrice: ComputedRef<(item: CourseItem | CoursePackage) => number>
  courseTrialPeriodExpired: ComputedRef<boolean>
  handle34ANextOnboardingAction: () => void
  trackCoursePurchaseIntended: () => void
  courseWithFreeModule: ComputedRef<{ courseId: string; moduleId: string }>
  findFreeModule: (courseId: string) => CourseModuleState | null
  courseExpiredAt: ComputedRef<string | null>
  showInTrialCourseTimer: ComputedRef<boolean>
}

export default function useCourse(): useCourseReturn {
  const { convertToRoman } = useCommonMixin()
  const { bookmarkAdded, bookmarkRemoved, coursePurchaseIntended } = useSegment()
  const { trackGamificationActivity } = useGamification()
  const { isLargeDesktop } = useBreakpoint()
  const { translateString } = useI18n()

  const currentCourseId = computed(() => eventBus.$route.params.courseId)
  const moduleId = computed(() => eventBus.$route.params.moduleId)
  const lessonId = computed(() => eventBus.$route.params.lessonId)
  const courseExamId = computed(() => eventBus.$route.params.examId)
  const packageId = computed(() => eventBus.$route.params.packageId)
  const examCompletionIdParam = computed(() => eventBus.$route.params.completionId) // this is for a specific exam completion

  const course = computed(() => CourseModule.course)
  const courseBundle = computed(() => CourseModule.courseBundle)
  const courseModules = computed(() => CourseModule.coursesModuleMap[currentCourseId.value])
  const canBuyCourses = computed(() => UserModule.currentCompany?.userCanBuyCourses)

  const onLessonPage = computed(() => eventBus.$route.name === 'Lesson')
  const onModulePage = computed(() => eventBus.$route.name === 'CourseModule')
  const onKnowledgeCheckInfo = computed(() => eventBus.$route.name === 'KnowledgeCheckInfo')
  const fetchingEditorDependencies = computed(() => {
    return (
      (onLessonPage.value && (CourseModule.currentLesson === null || CourseModule.currentLessonQuestions === null)) ||
      (onModulePage.value && CourseModule.currentModule(currentCourseId.value, moduleId.value) === null) ||
      CourseModule.currentCourseModule === null ||
      courseModules.value === undefined ||
      course.value === null
    )
  })

  const currentModule = computed(() => {
    return (
      CourseModule.currentModule(currentCourseId.value, moduleId.value) || {
        id: '',
        lessons: [],
        orderingNumber: 0,
        isFree: false,
        courseGroupId: null,
        guidedQuiz: null,
        name: '',
        disabled: false,
      }
    )
  })

  const firstCourseModule = computed(() => {
    return (courseId: string, courseGroupId?: string) => {
      const courseModules = CourseModule.courseModules(courseId, courseGroupId)
      if (courseModules) return courseModules[0]
      return null
    }
  })

  const currentLesson = computed(() => CourseModule.currentLesson)
  const currentLessonQuestions = computed(() => CourseModule.currentLessonQuestions)

  const quizId = computed(() => {
    if (!currentLesson.value) return ''
    return currentLesson.value.quiz ? currentLesson.value.quiz.meta.quizId : ''
  })

  const currentModuleLessonsLength = computed(() => currentModule.value.lessons.length)

  const currentModuleTitle = computed(() => {
    return translateString('courses.module') + ` ${convertToRoman(currentModuleIndex.value + 1)} `
  })

  const currentMobileModuleTitle = computed(() => {
    return translateString('courses.module') + `: <span>${currentModule.value?.name}</span>`
  })

  const currentLessonTitle = computed(() => currentLesson.value?.title || CourseModule.currentLessonTitle || '')

  const studyingStatus = computed(() => {
    let lessonStatus = ''
    if (currentLesson.value) {
      const lessonIndex = currentModule.value.lessons.findIndex((lesson) => lesson.id === currentLesson.value?.id)
      lessonStatus = lessonIndex === -1 ? '' : '- ' + translateString('courses.lesson') + ` ${lessonIndex + 1}`
    }
    return currentModuleTitle.value + lessonStatus
  })

  const showNextCTA = computed(() => (ctaStatus: string) => {
    return (
      !EditorModule.editMode &&
      !!ctaStatus &&
      (canBuyCourses.value || ctaStatus !== translateString('courses.buyCourse'))
    )
  })

  const courseSectionsAssignedCourses = computed(() => CourseModule.courseSectionsAssignedCourses)

  const hasPurchasedAtLeastACourse = computed(
    () => courseSectionsAssignedCourses.value?.some((c) => c.purchased) ?? false,
  )

  const inTrialCourses = computed(() => courseSectionsAssignedCourses.value?.filter((c) => c.trial) ?? [])

  const fetchingCourseInfo = computed(() => {
    if (onKnowledgeCheckInfo.value) return CourseModule.course === null
    return (
      CourseModule.course === null || (CourseModule.course.courseBundle ? CourseModule.courseBundle === null : false)
    )
  })

  const productPrice = computed(
    () => (item: CourseItem | CoursePackage) => item?.shopifyProductPrice ?? item.product?.price.amount ?? 0,
  )

  const courseTrialPeriodExpired = computed(() => CourseModule.courseTrialPeriodExpired)

  const routeToFirstCourseModule = (courseId: string) => {
    eventBus.$router
      .push({
        name: 'CourseModule',
        params: { courseId: courseId, moduleId: firstCourseModule.value(courseId)!.id! },
      })
      .catch(() => {
        return
      })
  }

  const courseModule = (lessonId: string) => {
    return courseModules.value?.find((mod) => mod.lessons.some((lesson) => lesson.id === lessonId))
  }

  const courseModuleLesson = (lessonId: string) =>
    courseModule(lessonId)?.lessons.find((lesson) => lesson.id === lessonId)

  const completeALesson = (lessonId: string, completionId?: string): void => {
    const lesson = courseModuleLesson(lessonId)

    if (!lesson) return

    if (lesson.type === 'Quiz' && lesson.quizMeta) {
      lesson.quizMeta!.passed = true
      lesson.quizMeta!.completionId = completionId!
      lesson.quizMeta!.state = 'completed'
    } else if (!lesson.completed) {
      lesson.completed = true
      trackGamificationActivity('LESSON_COMPLETED', {
        courseId: currentCourseId.value,
        moduleId: moduleId.value,
        lessonId: lessonId,
      })
    }
  }

  const setLessonHasNote = (lessonId: string) => {
    const lesson = courseModuleLesson(lessonId)
    if (lesson) {
      lesson.hasNote = true
    }
  }

  const nextEnabledModule = computed(() => {
    return (courseId: string, courseGroupId?: string) => {
      const courseModules = CourseModule.courseModules(courseId, courseGroupId)
      const enabledModules = courseModules.filter((mod) => !mod.disabled)
      const courseModule = courseModules?.find((mod) =>
        !mod.disabled && mod.lessons.length > 0 ? mod.lessons.some((lesson) => !lesson.completed) : false,
      )
      if (courseModule) return courseModule
      if (!currentModule.value.id || (enabledModules.length > 1 && currentModule.value.id)) {
        const currentModuleIndex = enabledModules.findIndex((mod) => mod.id === currentModule.value.id)
        return enabledModules[currentModuleIndex + 1] ?? enabledModules[0] ?? null
      }
      return null
    }
  })

  const nextSiblingModule = computed(() => {
    return (courseId: string, courseGroupId?: string) => {
      const courseModules = CourseModule.courseModules(courseId, courseGroupId).filter((mod) => !mod.disabled)
      const currentIndex = courseModules.findIndex((mod) => mod.id === currentModule.value.id)
      return currentIndex === -1 ? null : (courseModules[currentIndex + 1] ?? null)
    }
  })

  const quizMetaCount = computed(() => {
    const count = {
      passedQuizCount: 0,
      totalQuizCount: 0,
    }
    courseModules.value?.forEach((module) => {
      module.lessons.forEach((lesson) => {
        if (lesson.type === 'Quiz') {
          count.totalQuizCount++
          lesson.quizMeta?.passed ? count.passedQuizCount++ : ''
        }
      })
    })
    return count
  })

  const isPurchasedCourse = computed(() => CourseModule.course?.purchased)
  const isTrialCourse = computed(() => CourseModule.course?.trial)

  const nextModuleIndex = (courseGroupId?: string): number => {
    const nextMod = nextEnabledModule.value(currentCourseId.value, courseGroupId)
    const modId = moduleId.value || nextMod?.id || ''
    if (!modId) return -1

    const courseModules = CourseModule.courseModules(currentCourseId.value, courseGroupId)
    return courseModules.findIndex((mod) => mod.id === modId)
  }

  const nextCourseModule = (courseGroupId?: string) => {
    return nextEnabledModule.value(currentCourseId.value, courseGroupId)
  }

  const nextLessonIndex = (courseGroupId?: string): number => {
    const nextIndex = nextCourseModule(courseGroupId)?.lessons.findIndex((lesson) => !lesson.completed)
    if (nextIndex === undefined) return 0
    return nextIndex === -1 ? 0 : nextIndex
  }

  const nextLesson = (courseGroupId?: string) => {
    const _nextLesson = nextCourseModule(courseGroupId)?.lessons.find((lesson) => !lesson.completed)
    return _nextLesson === undefined ? nextCourseModule(courseGroupId)?.lessons[0] : _nextLesson
  }

  const currentModuleIndex = computed(() => {
    if (currentModule.value.courseGroupId) {
      const courseModules = CourseModule.courseGroupModules(currentCourseId.value, currentModule.value.courseGroupId)
      return courseModules!.findIndex((mod) => mod.id === currentModule.value.id)
    }
    return CourseModule.courseModules(currentCourseId.value).findIndex((mod) => mod.id === currentModule.value.id)
  })

  const currentLessonIndex = computed(() => {
    const currentModuleLessons = currentModule.value?.lessons
    return currentModuleLessons?.findIndex((lesson) => {
      return lesson.id === (lessonId.value || '')
    })
  })

  const fetchCourses = () => {
    eventBus.$emit('turn-on-loader')
    CourseModule.getCourses().finally(() => {
      eventBus.$emit('turn-off-loader')
    })
  }

  const fetchAllCourseModules = async (courseIds: string[]) => {
    const mappedModulePromises = courseIds.map((courseId) => CourseModule.getCourseModules(courseId)) as Promise<{
      courseId: string
      modules: CourseModuleState[]
    }>[]
    return await Promise.all(mappedModulePromises)
  }

  const completedModules = computed(() => {
    return (course: CourseItem) => {
      if (!course) return 0
      if (course.courseGroups.length) {
        const states: boolean[] = []
        course.courseGroups.forEach((group) => {
          states.push(...CourseModule.completedModuleStates(course.id, group.id))
        })
        return states.filter((state) => state).length
      }
      return CourseModule.completedModuleStates(course.id || '').filter((state) => state).length
    }
  })

  const remainingModules = computed(() => {
    if (course.value?.courseGroups.length) {
      const states: number[] = []
      course.value?.courseGroups.forEach((group) => {
        states.push(CourseModule.numberOfCourseModules(currentCourseId.value, group.id))
      })
      const numberOfModules = states.reduce((acc, value) => {
        acc += value
        return acc
      }, 0)
      return numberOfModules - completedModules.value(course.value)
    }
    return (course.value?.numberOfModules || 0) - completedModules.value(course.value!)
  })

  const bookmarkCourseLesson = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => {
    if (courseId && moduleId && lessonId) {
      BookmarkApi.bookmarkCourseLesson({
        courseId: courseId,
        moduleId: moduleId,
        lessonId: lessonId,
      }).then(async (status) => {
        if (status === 201) {
          eventBus.$toasted.success(translateString('bookmarks.bookmarkAdded'))
          trackGamificationActivity('LESSON_BOOKMARKED', { courseId: courseId, moduleId: moduleId, lessonId: lessonId })
          await BookmarksModule.getUserBookmarks()
          await bookmarkAdded(courseId, courseName, moduleId, moduleName, lessonId, lessonName)
        }
      })
    }
  }

  const unBookmarkCourseLesson = (
    courseId: string,
    courseName: string,
    moduleId: string,
    moduleName: string,
    lessonId: string,
    lessonName: string,
  ) => {
    if (courseId && moduleId && lessonId) {
      BookmarkApi.deleteCourseLessonBookmark({
        courseId: courseId,
        moduleId: moduleId,
        lessonId: lessonId,
      }).then(async (status) => {
        if (status === 200) {
          eventBus.$toasted.success(translateString('bookmarks.bookmarkRemoved'))
          trackGamificationActivity('LESSON_UNBOOKMARKED', {
            courseId: courseId,
            moduleId: moduleId,
            lessonId: lessonId,
          })
          await BookmarksModule.getUserBookmarks()
          await bookmarkRemoved(courseId, courseName, moduleId, moduleName, lessonId, lessonName)
          eventBus.$emit('close-modal')
        }
      })
    }
  }

  const courseLessonIsBookmarked = computed(() => {
    return (lessonId: string) => {
      if (!BookmarksModule.bookmarks) return false
      return !!BookmarksModule.bookmarks[lessonId]
    }
  })

  const inCourseExamResultsPage = computed((): boolean => eventBus.$route.name === 'CourseExamResults')

  const isCourseExamPaused = computed((): boolean => CourseModule.examCompletionState === 'paused')

  const isCourseExamTimedOut = computed((): boolean => CourseModule.examCompletionState === 'timedOut')

  const currentExamQuestion = computed(() => CourseModule.currentExamQuestion)

  const reviewingQuestion = computed((): boolean => eventBus.$route.name === 'CourseExamReviewAnswers')

  const totalPartQuestions = computed(() => {
    return (index: number) => {
      const part = course.value?.exam?.parts[index]
      return part ? partQuestionsLength(part) : 0
    }
  })

  const partQuestionsLength = (part: CourseExamPart) => {
    return part.subjects.reduce((acc, subject) => {
      acc += subject.pickFromLessons.reduce((pAcc, value) => {
        pAcc += value.numberOfQuestions
        return pAcc
      }, 0)
      return acc
    }, 0)
  }

  const totalExamQuestions = computed(() => {
    return (
      course.value?.exam?.parts.reduce((acc, part) => {
        acc += partQuestionsLength(part)
        return acc
      }, 0) || 0
    )
  })

  const currentPartIndex = computed(() => {
    if (currentExamQuestion.value?.partId) {
      return course.value!.exam!.parts.findIndex((part) => part.partId === currentExamQuestion.value!.partId)
    }
    return 0
  })

  const answeredQuestions = computed(() => {
    return CourseModule.courseExamQuestionsAnswered
  })

  const examTimeRemaining = computed(() => {
    return CourseModule.timeRemainingInSeconds
  })

  const latestExamCompletionId = computed(() => {
    return CourseModule.latestExamCompletionId
  })

  const guidedQuizDuration = computed(() => {
    return (numberOfQuestions: number) => {
      const durationPerQuestion = 1.5
      return numberOfQuestions * durationPerQuestion
    }
  })

  const showStartCourseForFreeModal = (courseId: string, hideCloseButton = false, callback?: () => void) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'StartCourseForFreeModal',
      modalProps: {
        courseId: courseId,
        callback,
      },
      hideCloseButton,
      cssClass: 'start-course-for-free-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const progressPercentage = computed(() => {
    return (courseId: string, courseGroupId?: string) => {
      const progress = CourseModule.courseModules(courseId, courseGroupId).reduce(
        (acc, mod) => {
          acc.totalLessons += mod.lessons.length
          acc.completedLessons += mod.lessons.filter((l) => l.completed).length
          return acc
        },
        { totalLessons: 0, completedLessons: 0 } as { [key: string]: number },
      ) || { totalLessons: 0, completedLessons: 0 }

      return Math.round((progress.completedLessons / (progress.totalLessons || 1)) * 100)
    }
  })

  const groupCourses = async (
    courses: CourseItem[],
    skipPurchases = false,
    skipBundles = false,
    showInProgress = false,
  ) => {
    const result = { trial: [], others: [] } as {
      [key: string]: (CourseItem | CoursePackage)[]
    }
    for (let i = 0; i < courses.length; i++) {
      const course = courses[i]
      if (course.purchased && !skipPurchases) {
        if (!result.purchased) result.purchased = []
        ;(result.purchased as CourseItem[]).push(course)
      } else if (course.trial) (result.trial as CourseItem[]).push(course)
      else if (course.courseBundle && !skipBundles) {
        if (!result.bundles) result.bundles = []
        if (
          !(result.bundles as CoursePackage[]).some((b) => b.courseBundleId === course.courseBundle?.courseBundleId)
        ) {
          const courseBundle = (await CourseModule.getPackageBundle(
            course.courseBundle!.courseBundleId,
          )) as CoursePackage

          const coursesInBundle = courseBundle.courses
          const coursesToReplaceInBundle: CourseItem[] = []

          // replace courses in the bundle with the ones scoped to the user i.e. courses[] above
          coursesInBundle.forEach((course) => {
            const _course = courses.find((c) => c.id === course.id)
            if (_course) {
              coursesToReplaceInBundle.push(_course)
            }
          })
          courseBundle.courses = coursesToReplaceInBundle

          if (courseBundle.courses.every((c) => c.state === 'ready' && !c.purchased)) {
            ;(result.bundles as CoursePackage[]).push(courseBundle)
          }
        }
        ;(result.others as CourseItem[]).push(course)
      } else if (course.state === 'in_progress' && showInProgress) {
        if (!result.inProgress) result.inProgress = []
        ;(result.inProgress as CourseItem[]).push(course)
      } else (result.others as CourseItem[]).push(course)
    }
    return result
  }

  const showWebinar = computed(() => {
    return !!course.value?.webinar?.enabled
  })

  const openWebinarModal = () => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'WebinarModal',
      modalProps: {
        webinar: course.value?.webinar || {},
        purchasedCourse: course.value?.purchased,
      },
      cssClass: 'webinar-modal-wrapper',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const openLessonModal = (data: {
    lessonId: string
    moduleId: string
    courseId?: string
    teaserImageUrl?: string
    formatContent?: boolean
    lessonToRender?: Lesson
  }) => {
    eventBus.$emit('show-modal', {
      modalContentComponent: 'LessonModal',
      modalProps: {
        lessonId: data.lessonId,
        moduleId: data.moduleId,
        courseId: data.courseId,
        teaserImageUrl: data.teaserImageUrl,
        formatContent: data.formatContent,
        lessonToRender: data.lessonToRender,
      },
      cssClass: 'lesson-modal',
      modalCloseCallback: (callback: () => void) => {
        if (typeof callback === 'function') callback()
      },
    })
  }

  const courseSections = computed(() => {
    if (EditorModule.editMode && isLargeDesktop.value) return EditorModule.courseSections
    return CourseModule.courseSections
  })

  const fetchGroupCourses = () => {
    UserModule.getMembersGroups().then((groups) => {
      groups.forEach((group) => {
        CourseModule.getGroupAssignedCourses(group.id)
      })
    })
  }

  const courseGroupIds = computed(() => (courseId: string) => {
    const groupIds = []
    const groupKeys = Object.keys(CourseModule.assignedGroupCoursesMap)
    for (const key of groupKeys) {
      const groupHasCourse = CourseModule.assignedGroupCoursesMap[`${key}`].find((c) => c.id === courseId)
      if (groupHasCourse) {
        groupIds.push(key)
      }
    }
    return groupIds
  })

  const handle34ANextOnboardingAction = () => {
    if (courseWithFreeModule.value.courseId && courseWithFreeModule.value.moduleId) {
      redirectToCourseModule(courseWithFreeModule.value.courseId, courseWithFreeModule.value.moduleId)
    } else {
      const firstCourse = courseSectionsAssignedCourses.value![0]

      if (firstCourse) {
        const moduleId = findFirstCourseModuleId(firstCourse.id)

        if (moduleId) {
          redirectToCourseModule(firstCourse.id, moduleId)
        } else {
          redirectToHomePage()
        }
      } else {
        redirectToHomePage()
      }
    }
  }

  const findFreeModule = (courseId: string) => {
    return CourseModule.courseModules(courseId).find((module) => module.isFree) ?? null
  }

  const courseWithFreeModule = computed(() => {
    const assignedCourseIds = courseSectionsAssignedCourses.value?.map((course) => course.id)
    let result = null

    if (currentCourseId.value && !assignedCourseIds?.includes(currentCourseId.value)) {
      // Course is directly accessed via a link
      const freeModule = findFreeModule(currentCourseId.value)
      if (freeModule) {
        result = {
          courseId: currentCourseId.value,
          moduleId: freeModule.id,
        }
      }
    } else {
      for (const courseId of assignedCourseIds || []) {
        const freeModule = findFreeModule(courseId)
        if (freeModule) {
          result = {
            courseId,
            moduleId: freeModule.id,
          }
          break // Stop as soon as the first free module is found
        }
      }
    }

    return result || { courseId: '', moduleId: '' } // Return empty if no free module found
  })

  const findFirstCourseModuleId = (courseId: string) => {
    const firstCourseModule = CourseModule.courseModules(courseId)[0]
    return firstCourseModule?.id || null
  }

  const redirectToCourseModule = (courseId: string, moduleId: string) => {
    const route = eventBus.$router.resolve({
      name: 'CourseModule',
      params: { courseId, moduleId },
    })
    window.location.assign(route.href)
  }

  const redirectToHomePage = () => {
    window.location.href = '/'
  }

  const trackCoursePurchaseIntended = async () => {
    let page = ''
    if (eventBus.$route.name === 'CourseDetails') {
      page = 'course_details_page'
    } else if (eventBus.$route.name === 'CourseModule') {
      page = 'course_module_page'
    } else if (eventBus.$route.name === 'Lesson') {
      page = 'lesson_page'
    }
    await coursePurchaseIntended(currentCourseId.value, course.value!.title, `${page}`)
  }

  const courseExpiredAt = computed(() => course.value?.expiredAt ?? null)

  const courseHasLimitedTrialTime = computed(() => {
    const courseIdsString = process.env.VUE_APP_COURSE_TRIAL_LIMIT_IDS
    if (!courseIdsString) return false

    const courseIds = courseIdsString.split(',')
    return courseIds.includes(course.value?.id)
  })

  const showInTrialCourseTimer = computed(() => courseHasLimitedTrialTime.value && course.value?.trial)

  return {
    currentCourseId,
    moduleId,
    lessonId,
    courseModules,
    canBuyCourses,
    currentModule,
    firstCourseModule,
    currentModuleLessonsLength,
    currentLesson,
    currentLessonQuestions,
    quizId,
    studyingStatus,
    showNextCTA,
    currentModuleTitle,
    currentMobileModuleTitle,
    currentLessonTitle,
    routeToFirstCourseModule,
    completeALesson,
    setLessonHasNote,
    nextEnabledModule,
    nextSiblingModule,
    course,
    courseBundle,
    nextLesson,
    quizMetaCount,
    isPurchasedCourse,
    isTrialCourse,
    nextModuleIndex,
    nextCourseModule,
    nextLessonIndex,
    currentModuleIndex,
    currentLessonIndex,
    hasPurchasedAtLeastACourse,
    courseSectionsAssignedCourses,
    fetchingCourseInfo,
    productPrice,
    courseTrialPeriodExpired,
    inTrialCourses,
    courseExamId,
    fetchCourses,
    completedModules,
    remainingModules,
    bookmarkCourseLesson,
    unBookmarkCourseLesson,
    courseLessonIsBookmarked,
    inCourseExamResultsPage,
    isCourseExamPaused,
    isCourseExamTimedOut,
    currentExamQuestion,
    reviewingQuestion,
    totalPartQuestions,
    totalExamQuestions,
    currentPartIndex,
    answeredQuestions,
    examTimeRemaining,
    latestExamCompletionId,
    examCompletionIdParam,
    packageId,
    fetchAllCourseModules,
    showStartCourseForFreeModal,
    guidedQuizDuration,
    fetchingEditorDependencies,
    progressPercentage,
    groupCourses,
    showWebinar,
    openWebinarModal,
    courseSections,
    onLessonPage,
    onModulePage,
    onKnowledgeCheckInfo,
    openLessonModal,
    fetchGroupCourses,
    courseGroupIds,
    handle34ANextOnboardingAction,
    trackCoursePurchaseIntended,
    courseWithFreeModule,
    findFreeModule,
    courseExpiredAt,
    showInTrialCourseTimer,
  }
}
