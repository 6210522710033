<script setup lang="ts">
  import { computed } from 'vue'
  import type { PropType } from 'vue'
  import useShopifyClient from '@/composables/useShopifyClient'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import { UserModule } from '@/store/modules/user'
  import type { CourseVertical, PreCheckoutProductProperties } from '@/services/interfaces/Course'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import ProductIconHighlights from '@/components/course/pre-checkout/ProductIconHighlights.vue'

  const { handleShopifyOrStripeCheckout } = useShopifyClient()
  const { checkmark } = useIcons()

  const props = defineProps({
    preCheckoutProperties: {
      type: Object as PropType<PreCheckoutProductProperties>,
      required: true,
    },
    vertical: {
      type: String as PropType<CourseVertical>,
      required: true,
    },
  })

  const productType = computed(() => {
    const productTypeMap: Record<string, string> = {
      Package: 'Schriftlich + Mündlich',
      Written: 'Schriftlich',
      Oral: 'Mündlich',
    }

    return productTypeMap[props.preCheckoutProperties?.productType || '']
  })

  const handleCheckout = () => {
    if (props.preCheckoutProperties?.shopifyProductVariantId) {
      handleShopifyOrStripeCheckout(props.preCheckoutProperties?.shopifyProductVariantId, UserModule.email || null)
    }
  }
</script>

<template lang="pug">
.pre-checkout-course-tile(
  :class='{ package: preCheckoutProperties.productType === "Package" }',
  @click='handleCheckout'
)
  ProductIconHighlights(
    :product-type='preCheckoutProperties.productType',
    :vertical='vertical'
  )
  .product-price-details
    .spare
      h5(v-if='preCheckoutProperties.productType === "Package"') Spare 99€
    h2.product-type {{ productType }}
    .product-price.ketch-font-bold
      .price {{ preCheckoutProperties.price }}
      .currency €
    h6.ketch-opacity-40 Umsatzsteuerfreie Leistung
  KetchUpButton.primary.ketch-w-full.ketch-rounded-xlarge(@click.native.stop='handleCheckout')
    h5.ketch-font-bold Jetzt kaufen und loslegen
  .product-details
    .product-detail(
      :key='detail',
      v-for='detail in preCheckoutProperties.productDetails'
    )
      .ketch-w-c15
        SVGRenderer(
          :has-hover='false',
          :icon='checkmark',
          :stroke-color='"#008a40"',
          :width='"15px"'
        )
      h5 {{ detail }}
</template>

<style scoped lang="postcss">
  .pre-checkout-course-tile {
    @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-p-c20 ketch-bg-white ketch-rounded-large;
    @apply ketch-transition-transform ketch-ease-in-out ketch-duration-500;
    @apply hover:ketch-border-primary-color hover:md:ketch-scale-[103%];
    @apply xs3:ketch-max-w-[384px] md:ketch-space-y-c20 md:ketch-cursor-pointer;

    &.package {
      @apply ketch-border-4 ketch-border-primary-color;
    }

    .product-price-details {
      @apply ketch-flex ketch-flex-col ketch-items-center ketch-justify-center;
      .spare {
        @apply md:ketch-h-c25;
        h5 {
          @apply ketch-bg-[#ff000f] ketch-text-[#ff000f] ketch-bg-opacity-10 ketch-rounded-small ketch-py-c2 ketch-px-c5;
        }
      }
      .product-type {
        @apply ketch-mt-c10 ketch-font-bold ketch-text-center ketch-leading-[1.4] md:ketch-mt-c15;
      }
      .product-price {
        @apply ketch-flex ketch-items-start ketch-space-x-c5 ketch-mt-c10 md:ketch-mt-c20;
        .price {
          @apply ketch-leading-[1.1] ketch-text-[40px] md:ketch-text-[50px] xl:ketch-text-[70px];
        }
        .currency {
          @apply ketch-text-[20px] md:ketch-text-[30px];
        }
      }
    }

    .product-details {
      @apply ketch-flex ketch-flex-col ketch-space-y-c10;
      .product-detail {
        @apply ketch-flex ketch-items-center ketch-space-x-c10;
      }
    }
  }
</style>
