<script setup lang="ts">
  import { computed } from 'vue'
  import type { PropType } from 'vue'
  import type { CourseVertical, PreCheckoutProductType } from '@/services/interfaces/Course'
  import useIcons from '@/composables/useIcons'

  const props = defineProps({
    vertical: {
      type: String as PropType<CourseVertical>,
      default: null,
    },
    productType: {
      type: String as PropType<PreCheckoutProductType>,
      required: true,
    },
  })

  const { written, audio, moduleCompleted, moduleInProgress } = useIcons()

  const backgroundColor = computed(() => {
    const colorMap: Record<string, string> = {
      '34i': '#f1c49e',
      '34d': '#b8d9c8',
      '34f': '#f5e37e',
      AEVO: '#bbdcec',
    }

    return colorMap[props.vertical] ?? '#f1c49e'
  })
</script>

<template lang="pug">
.product-icon-highlights(:style='{ backgroundColor: backgroundColor }')
  h2.best-seller(v-if='productType === "Package"') Bestseller
  .for-package(v-if='productType === "Package"')
    img.written(:src='written')
    img.audio(:src='audio')
    img.module-completed(:src='moduleCompleted')
  .for-written(v-else-if='productType === "Written"')
    img.written(:src='written')
    img.module-in-progress(:src='moduleInProgress')
  .for-audio(v-else)
    img.audio(:src='audio')
    img.module-completed(:src='moduleCompleted')
</template>

<style scoped lang="postcss">
  .product-icon-highlights {
    @apply ketch-relative ketch-rounded-large ketch-aspect-[calc(38/18)];
    .best-seller {
      @apply ketch-rounded-t-large ketch-absolute ketch-rotate-45 ketch-right-[-46px] ketch-bg-[#008a40];
      @apply ketch-px-c15 ketch-py-c2 ketch-text-white ketch-font-bold ketch-z-[2] ketch-top-[-4px];
      @apply md:ketch-right-[-60px] md:ketch-px-c30 md:ketch-top-[3px];
    }

    .for-package {
      img.written {
        @apply ketch-absolute ketch-left-[-30px] ketch-top-[-10px] ketch-z-[2] ketch-w-[130px] md:ketch-w-[150px];
      }
      img.audio {
        @apply ketch-absolute ketch-top-[35px] ketch-left-c30 ketch-z-[1] ketch-w-[270px];
      }
      img.module-completed {
        @apply ketch-absolute ketch-left-[5%] ketch-bottom-[-60%] ketch-w-[90%] ketch-z-[2];
        @apply md:ketch-bottom-[-80px];
      }
    }

    .for-written {
      img.written {
        @apply ketch-absolute ketch-left-[-40px] ketch-top-[-10%] ketch-z-[2] ketch-w-[200px];
      }
      img.module-in-progress {
        @apply ketch-absolute ketch-right-[5%] ketch-bottom-[5px] ketch-w-[70%] ketch-z-[1];
        filter: blur(1px);
      }
    }

    .for-audio {
      img.audio {
        @apply ketch-absolute ketch-right-0 ketch-top-[5px] ketch-w-[300px];
      }
      img.module-completed {
        @apply ketch-absolute ketch-right-[-10%] ketch-bottom-[-95px];
        @apply md:ketch-right-[-20%] md:ketch-bottom-[-90px];
        filter: blur(1px);
      }
    }
  }
</style>
