import { UserModule } from '@/store/modules/user'
import eventBus from '@/main'
import useI18n from '@/composables/useI18n'
import useSegment from '@/composables/useSegment'
import { useRoute, useRouter } from 'vue-router/composables'
import { computed, ComputedRef } from 'vue'
import { CourseModule } from '@/store/modules/course'
import useCommonMixin from '@/composables/useCommonMixin'
import CommonApi from '@/services/api/CommonApi'
import CheckoutApi from '@/services/api/CheckoutApi'
import * as Sentry from '@sentry/vue'

interface useShopifyClientReturn {
  createShopifyCheckout: (email?: string, shopifyVariantId?: string) => Promise<void>
  handleSkgCheckout: () => Promise<void>
  useStripeCheckout: ComputedRef<boolean>
  handleShopifyOrStripeCheckout: (shopifyProductVariantId: string, userEmail: string | null) => Promise<void>
}

export default function useShopifyClient(): useShopifyClientReturn {
  const { translateString } = useI18n()
  const { invalidEmailShopifyCheckout, stripeCheckoutUsed, skgCoursePurchaseIntended } = useSegment()
  const { isSachkundeCompany, windowOpenProxyCheck, reduceTrialFrictionEnabled } = useCommonMixin()
  const router = useRouter()
  const route = useRoute()

  const skgCourses = [
    {
      shortTitle: '34i',
      id: '63d7d5bb-e8e9-429d-b360-cefdecd80448',
    },
    {
      shortTitle: '34i',
      id: '489dd660-9a78-4f94-8076-1e8f20a451e2',
    },
    {
      shortTitle: '34d',
      id: '8de44221-a055-4cdb-8ce5-d7ac148cb49e',
    },
    {
      shortTitle: '34d',
      id: 'ef7607dd-bb67-42f7-b0f8-a0f1a437fdd9',
    },
    {
      shortTitle: '34f',
      id: '84be496a-b904-4184-b456-98736fea9259',
    },
    {
      shortTitle: '34f',
      id: '284424aa-1a0e-4c60-a5e0-d8df6d9d1868',
    },
    {
      shortTitle: 'aevo',
      id: '4dc07be7-7aab-43a0-ae70-4b5f00b5a79e',
    },
    {
      shortTitle: 'aevo',
      id: 'd1643bae-db0b-4778-9662-7080f21ea5e4',
    },
  ]

  const flattenedSkgCourseIds = computed(() => skgCourses.map((c) => c.id))
  const course = computed(() => CourseModule.course)
  const courseBundle = computed(() => CourseModule.courseBundle)
  const inPackageDetails = computed(() => route.name === 'PackageDetails')
  const inUsersAndGroups = computed(() => route.name === 'UsersAndGroups')

  const useStripeCheckout = computed(() => {
    if (inPackageDetails.value) return !CourseModule.courseBundle?.shopifyProductId
    return !course.value?.shopifyProductId
  })

  const createShopifyCheckout = async (email?: string, shopifyVariantId?: string) => {
    const userEmail = email || UserModule.email || null
    if (useStripeCheckout.value && !inUsersAndGroups.value) {
      await handleStripeCheckout(userEmail)
    } else if (
      isSachkundeCompany.value &&
      flattenedSkgCourseIds.value.includes(course.value?.id ?? '') &&
      !inUsersAndGroups.value
    ) {
      await handleSkgCheckout()
    } else {
      const shopifyProductVariantId =
        shopifyVariantId ||
        (inPackageDetails.value ? courseBundle.value?.shopifyProductVariantId : course.value?.shopifyProductVariantId)

      if (course.value?.isIddCourse) {
        await handleIDDCourseCheckout(shopifyProductVariantId!, userEmail)
      } else {
        eventBus.$emit('close-modal')
        eventBus.$emit('turn-on-loader')

        await handleShopifyOrStripeCheckout(shopifyProductVariantId!, userEmail)
      }
    }
  }

  const handleSkgCheckout = async () => {
    const skgCourse = skgCourses.find((c) => c.id === course.value?.id)
    if (skgCourse) {
      await skgCoursePurchaseIntended(UserModule.email, skgCourse.id)
      if (reduceTrialFrictionEnabled.value) {
        await router.push({ name: 'SKGCoursePreCheckout', params: { courseId: skgCourse.id } })
      } else {
        const url = `https://www.sachkundegurus.de/produkte/${skgCourse.shortTitle}`
        windowOpenProxyCheck(url, 'course_purchase_intended')
      }
    }
  }

  const openCheckoutPage = (url: string) => {
    windowOpenProxyCheck(url, 'course_purchase_intended')
  }

  const turnOffLoader = () => {
    eventBus.$emit('turn-off-loader')
  }

  const handleIDDCourseCheckout = async (shopifyProductVariantId: string, userEmail: string | null) => {
    const productVariantId = shopifyProductVariantId.split('/').pop()
    try {
      const resp = CheckoutApi.createSubscriptionCheckout(productVariantId!, userEmail)

      if ((await resp).checkoutUrl) {
        eventBus.$emit('close-modal')

        openCheckoutPage((await resp).checkoutUrl)
      }
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setTimeout(turnOffLoader, 2500)
    }
  }

  const handleShopifyOrStripeCheckout = async (shopifyProductVariantId: string, userEmail: string | null) => {
    try {
      const resp = await CheckoutApi.createShopifyCheckout(shopifyProductVariantId, userEmail)

      if (resp.checkoutUrl) {
        openCheckoutPage(resp.checkoutUrl)
      } else {
        await handleInvalidEmailFlow(shopifyProductVariantId, userEmail)
      }
    } catch (error: any) {
      await CommonApi.postSlackMessage(
        `Error creating Shopify checkout.\nError Code: ${error.code}\nError Message: ${error.message}`,
      )
      await handleInvalidEmailFlow(shopifyProductVariantId, userEmail)
    } finally {
      setTimeout(turnOffLoader, 2500)
    }
  }

  const handleInvalidEmailFlow = async (shopifyProductVariantId: string, userEmail: string | null) => {
    if (userEmail !== null) {
      await CommonApi.postSlackMessage(`Invalid email used in Shopify checkout.\nUser Email: ${userEmail}`)
      await invalidEmailShopifyCheckout(userEmail)
    }

    await tryCheckoutWithoutEmail(shopifyProductVariantId, userEmail)
  }

  const tryCheckoutWithoutEmail = async (shopifyProductVariantId: string, userEmail: string | null) => {
    const resp = await CheckoutApi.createShopifyCheckout(shopifyProductVariantId, null)

    if (resp.checkoutUrl) {
      openCheckoutPage(resp.checkoutUrl)
    } else {
      await redirectToLoginOrStripeCheckout(userEmail)
    }
  }

  const redirectToLoginOrStripeCheckout = async (userEmail: string | null) => {
    if (!UserModule.hasValidUser) {
      await router.push(`/login?redirect=${route.fullPath}`).catch(() => {})
    } else {
      if (inUsersAndGroups.value) {
        eventBus.$toasted.info(translateString('checkout.creatingCheckoutFailed'), { duration: 5000 })
      } else {
        await handleStripeCheckout(userEmail)
      }
    }
  }

  const handleStripeCheckout = async (userEmail: string | null) => {
    const courseIdentifier = inPackageDetails.value
      ? `Course Bundle: ${CourseModule.courseBundle?.courseBundleId || 'N/A'}`
      : `Course: ${CourseModule.course?.id || 'N/A'}`
    const userEmailMessage = userEmail ? `User Email: ${userEmail}. ` : ''
    const message = `Stripe checkout used. ${userEmailMessage}${courseIdentifier}. Current Path: ${route.fullPath}`

    await CommonApi.postSlackMessage(message)
    if (userEmail) await stripeCheckoutUsed(userEmail)
    await navigateStripeCheckoutAction()
  }

  const navigateStripeCheckoutAction = async () => {
    if (route.name === 'PackageDetails') {
      await router.push({ name: 'PackageCheckout' })
    } else if (route.name === 'CourseCheckout' && courseBundle.value) {
      await router.push({ name: 'PackageCheckout', params: { packageId: courseBundle.value!.courseBundleId } })
    } else {
      await router.push({ name: 'CourseCheckout' })
    }
  }

  return {
    useStripeCheckout,
    createShopifyCheckout,
    handleSkgCheckout,
    handleShopifyOrStripeCheckout,
  }
}
