<template lang="pug">
.start-course-for-free-modal(data-cy='start-course-for-free-modal')
  h2.title-with-underline {{ $t('courses.startFreeTrial') }}
  h5 {{ $t('courses.testTrainingInfo') }}
  .helper-info
    .item(
      :key='index',
      v-for='(item, index) in helperItems'
    )
      SVGRenderer(
        :has-hover='false',
        :icon='checkedCircle',
        height='16px',
        stroke-color='var(--primary-text-color)',
        width='16px'
      )
      h5(v-html='item')
  .to-module(
    @click='startTrial',
    data-cy='start-course-trial'
  )
    LoadingSpinner(v-if='startingTrial')
    h5(v-else) {{ $t('courses.startNow') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import useCourse from '@/composables/useCourse'
  import CourseApi from '@/services/api/CourseApi'
  import { CourseModule } from '@/store/modules/course'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useGamification from '@/composables/useGamification'
  import { useRoute } from 'vue-router/composables'
  import eventBus from '@/main'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import useSegment from '@/composables/useSegment'
  import { UserModule } from '@/store/modules/user'

  const props = defineProps({
    courseId: {
      type: String,
      required: true,
    },
    callback: Function as PropType<() => void>,
  })

  const route = useRoute()
  const { checkedCircle } = useIcons()
  const { currentCourseId, routeToFirstCourseModule, courseWithFreeModule } = useCourse()
  const { inWebExtension } = useCommonMixin()
  const { trackGamificationActivity } = useGamification()
  const { translateString } = useI18n()
  const { frontendCourseTrialStarted } = useSegment()
  const startingTrial = ref(false)

  const getCourseId = computed(() => props.courseId || currentCourseId.value)

  const getCourse = computed(() => CourseModule.courses?.find((c) => c.id === getCourseId.value))

  const freeCourseModulesCount = computed(() => {
    const courseModules = CourseModule.coursesModuleMap[props.courseId]
    return (
      courseModules?.reduce((acc, module) => {
        if (module.isFree) acc++
        return acc
      }, 0) || 0
    )
  })

  const inLessonPage = computed(() => {
    return route.name === 'Lesson'
  })

  const helperItems = [
    translateString('courses.completeAccess', { freeModulesCount: freeCourseModulesCount.value }),
    translateString('courses.unlimitedTime'),
    translateString('courses.notCharged'),
    translateString('courses.availableEverywhere'),
  ]

  const startTrial = () => {
    startingTrial.value = true
    CourseApi.startCourseTrial([getCourseId.value])
      .then(() => {
        if (props.callback) props.callback()
        if (getCourse.value && getCourse.value.trial) getCourse.value.trial = true
        if (CourseModule.course) CourseModule.course.trial = true
        if (inWebExtension.value) return
        if (!inLessonPage.value) {
          routeToModule()
        }
        frontendCourseTrialStarted(UserModule.email, getCourseId.value, getCourse.value?.title || '')
        trackGamificationActivity('COURSE_TRIAL_STARTED', {
          courseId: getCourseId.value,
          moduleId: null,
          lessonId: null,
        })
      })
      .finally(() => {
        eventBus.$emit('close-modal')
        startingTrial.value = false
      })
  }

  const routeToModule = () => {
    if (courseWithFreeModule.value.courseId && courseWithFreeModule.value.moduleId) {
      eventBus.$router
        .push({
          name: 'CourseModule',
          params: { courseId: courseWithFreeModule.value.courseId, moduleId: courseWithFreeModule.value.moduleId },
        })
        .catch(() => {
          return
        })
    } else {
      routeToFirstCourseModule(getCourseId.value)
    }
  }
</script>

<style lang="postcss" scoped>
  .start-course-for-free-modal {
    @apply ketch-relative;
    .title-with-underline {
      @apply ketch-pb-c10 ketch-mb-c15;
    }
    .helper-info {
      @apply ketch-mt-c30 ketch-space-y-c12;
      .item {
        @apply ketch-flex ketch-items-center ketch-space-x-c10;
      }
    }
    .to-module {
      @apply ketch-absolute ketch-bottom-0 ketch-right-0 ketch-min-w-[140px] ketch-cursor-pointer;
      @apply ketch-flex ketch-items-center ketch-justify-center ketch-h-c35 ketch-py-c10 ketch-rounded-normal;
      @apply ketch-bg-primary-color ketch-text-primary-foreground-color hover:ketch-bg-primary-hover-color;
    }
  }
</style>
