<template lang="pug">
.in-trial-course-timer(
  :class='{ "reduce-friction-mode": reduceTrialFrictionEnabled }',
  @click='shopifyCheckout'
)
  h5 {{ daysLeftText }}
</template>

<script setup lang="ts">
  import { computed, onBeforeUnmount, ref, watch } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { DateTime } from 'luxon'
  import useI18n from '@/composables/useI18n'
  import { CourseModule } from '@/store/modules/course'
  import useShopifyClient from '@/composables/useShopifyClient'

  const { course } = useCourse()
  const { translateString } = useI18n()
  const { daysDifferenceFromNow, reduceTrialFrictionEnabled } = useCommonMixin()
  const { createShopifyCheckout } = useShopifyClient()

  const usedTrialDays = ref(0)

  const timeTrialStarted = computed(() => course.value?.timeTrialStarted)

  const maxTrialDays = computed(() => Number(process.env.VUE_APP_MAX_COURSE_TRIAL_DAYS) || 7)

  const trialDaysLeft = computed(() => maxTrialDays.value - usedTrialDays.value)

  const daysLeftText = computed(() => {
    if (trialDaysLeft.value <= 0) return translateString('courses.courseTrials.freeAccessHasExpired')
    return translateString('courses.courseTrials.onlyXDaysLeft', { days: trialDaysLeft.value })
  })

  const setUsedTrialDays = () => {
    if (!timeTrialStarted.value) {
      const currentTimeISO = DateTime.now().toISO() as string
      usedTrialDays.value = daysDifferenceFromNow(currentTimeISO)
    } else {
      usedTrialDays.value = daysDifferenceFromNow(timeTrialStarted.value)
    }
  }

  const shopifyCheckout = async () => {
    if (reduceTrialFrictionEnabled.value) return
    await createShopifyCheckout()
  }

  watch(trialDaysLeft, (value) => {
    if (value <= 0) {
      CourseModule.setCourseTrialPeriodExpired(true)
    }
  })

  setUsedTrialDays()

  onBeforeUnmount(() => {
    CourseModule.setCourseTrialPeriodExpired(false)
  })
</script>

<style lang="postcss" scoped>
  .in-trial-course-timer {
    @apply md:ketch-mt-c15;
    &.reduce-friction-mode {
      @apply ketch-ml-c20;
      h5 {
        @apply ketch-text-header-foreground-color ketch-pointer-events-none md:ketch-font-bold;
      }
    }

    &:not(.reduce-friction-mode) {
      @apply ketch-flex ketch-items-start ketch-bg-primary-text-color ketch-px-c20 ketch-py-c10;
      @apply ketch-cursor-pointer md:ketch-rounded-large;
      h5 {
        @apply ketch-text-header-foreground-color md:ketch-text-background-color;
      }
    }
  }
</style>
