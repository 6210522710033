<template lang="pug">
transition(
  :name='showTransition ? "fade" : ""',
  mode='out-in'
)
  .guided-quiz-results(
    :key='points.correct + points.wrong',
    data-cy='results'
  )
    .correct
      SVGRenderer(
        :has-hover='false',
        :icon='checkedCheckboxSquareWhite',
        :stroke-color='isMobileDevice ? "white" : "var(--primary-text-color)"',
        height='15',
        width='15'
      )
      h5
        span {{ $t('correct') }}:
        span {{ points.correct }}
    .wrong
      SVGRenderer(
        :has-hover='false',
        :icon='wrongAnswerIcon',
        height='15',
        width='15'
      )
      h5
        span {{ $t('wrong') }}:
        span {{ points.wrong }}
</template>

<script setup lang="ts">
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useIcons from '@/composables/useIcons'
  import type { PropType } from 'vue'
  import useBreakpoint from '@/composables/useBreakpoint'

  defineProps({
    points: {
      required: true,
      type: Object as PropType<{ correct: number; wrong: number }>,
    },
    showTransition: {
      type: Boolean,
      default: true,
    },
  })

  const { checkedCheckboxSquareWhite, wrongAnswerIcon } = useIcons()
  const { isMobileDevice } = useBreakpoint()
</script>

<style lang="postcss"></style>
