<script setup lang="ts">
  import { computed, onMounted } from 'vue'
  import useCommonMixin from '@/composables/useCommonMixin'
  import useCourse from '@/composables/useCourse'
  import { CourseModule } from '@/store/modules/course'
  import type { PreCheckoutProductProperties } from '@/services/interfaces/Course'
  import PreCheckoutCourseTile from '@/components/course/pre-checkout/PreCheckoutCourseTile.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import useIcons from '@/composables/useIcons'
  import SVGRenderer from '@/components/common/SVGRenderer.vue'
  import useI18n from '@/composables/useI18n'
  import useBreakpoint from '@/composables/useBreakpoint'
  import { useRouter } from 'vue-router/composables'

  const router = useRouter()

  const { setIsLoadingComponentViewData, extractCourseVertical } = useCommonMixin()
  const { course, currentCourseId } = useCourse()
  const { leftArrowBox, curvedCircleCheckmark, rewind, arrowRightIndicator } = useIcons()
  const { translateString } = useI18n()
  const { isMobileDevice } = useBreakpoint()

  setIsLoadingComponentViewData(false)

  const loadingData = computed(() => course.value === null || CourseModule.courseBundles === null)

  const currentVertical = computed(() => extractCourseVertical(course.value!.shortTitle))

  const prependCurrentVertical = computed(() => {
    const vertical = currentVertical.value
    return vertical === 'AEVO' ? vertical : `§${vertical}`
  })

  const verticalProfession = computed(
    () => CourseModule.courseBundles?.find((bundle) => bundle.title.includes(currentVertical.value!))?.jobTitle,
  )

  const packagePreviewDetails = computed(() => {
    return [
      {
        iconUrl: leftArrowBox.value,
        text: translateString('courses.preCheckout.immediateAccess'),
      },
      {
        iconUrl: curvedCircleCheckmark.value,
        text: isMobileDevice.value
          ? translateString('courses.preCheckout.moneyBackMobileText')
          : translateString('courses.preCheckout.moneyBack'),
      },
      {
        iconUrl: rewind.value,
        text: translateString('courses.preCheckout.passRate'),
      },
    ]
  })

  const verticalCheckoutDetailsInfo: { [key: string]: PreCheckoutProductProperties[] } = {
    '34i': [
      {
        productType: 'Package',
        price: '599',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/46640947140',
        productDetails: [
          'Alle Inhalte und Vorteile des schriftlichen und des mündlichen Vorbereitungskurses',
          'Keine weiteren Unterlagen nötig, um die Prüfung zu bestehen',
        ],
      },
      {
        productType: 'Written',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/50735536772',
        productDetails: [
          'Lernskript mit allen relevanten Inhalten',
          'Prüfungssimulation in Echtzeit',
          'Über 600 Prüfungsfragen in IHK-Logik',
          'Expertenteam zur Beantwortung von Fragen',
        ],
      },
      {
        productType: 'Oral',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/50823302276',
        productDetails: [
          'Audio-Simulation der mündlichen Prüfung',
          'Musterlösungen zu allen Fallvorgaben',
          'Beratungs-Unterlagen für die Prüfung',
          'Vorbereitungs-Webinar mit einem IHK-Prüfer',
        ],
      },
    ],
    '34d': [
      {
        productType: 'Package',
        price: '599',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/32722148655242',
        productDetails: [
          'Alle Inhalte und Vorteile des schriftlichen und des mündlichen Vorbereitungskurses',
          'Keine weiteren Unterlagen nötig, um die Prüfung zu bestehen',
        ],
      },
      {
        productType: 'Written',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/32722016796810',
        productDetails: [
          'Lernskript mit allen relevanten Inhalten',
          'Prüfungssimulation in Echtzeit',
          'Über 1000 Prüfungsfragen in IHK-Logik',
          'Prüfungs-Webinar mit einem IHK-Prüfer',
        ],
      },
      {
        productType: 'Oral',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/32722135023754',
        productDetails: [
          'Audio-Simulation der mündlichen Prüfung',
          'Musterlösungen zu allen Fallvorgaben',
          'Beratungs-Unterlagen für die Prüfung',
          'Prüfungs-Webinar mit einem IHK-Prüfer',
        ],
      },
    ],
    '34f': [
      {
        productType: 'Package',
        price: '599',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/41501942972566',
        productDetails: [
          'Alle Inhalte und Vorteile des schriftlichen und des mündlichen Vorbereitungskurses',
          'Keine weiteren Unterlagen nötig, um die Prüfung zu bestehen',
        ],
      },
      {
        productType: 'Written',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/17729951203379',
        productDetails: [
          'Lernskript mit allen relevanten Inhalten',
          'Prüfungssimulation in Echtzeit',
          'Über 400 Prüfungsfragen in IHK-Logik',
          'Prüfungs-Webinar mit einem IHK-Prüfer',
        ],
      },
      {
        productType: 'Oral',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/41484308447382',
        productDetails: [
          'Audio-Simulation der mündlichen Prüfung',
          'Musterlösungen zu allen Fallvorgaben',
          'Beratungs-Unterlagen für die Prüfung',
          'Vorbereitungs-Webinar mit einem IHK-Prüfer',
        ],
      },
    ],
    AEVO: [
      {
        productType: 'Package',
        price: '599',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/44884318093580',
        productDetails: [
          'Alle Inhalte und Vorteile des schriftlichen und des mündlichen Vorbereitungskurses',
          'Keine weiteren Unterlagen nötig, um die Prüfung zu bestehen',
        ],
      },
      {
        productType: 'Written',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/44923366736140',
        productDetails: [
          'Umfangreiches Skript zu allen vier Handlungsfeldern',
          'Viele hundert Prüfungsfragen',
          'Eine Prüfungssimulation',
        ],
      },
      {
        productType: 'Oral',
        price: '349',
        shopifyProductVariantId: 'gid://shopify/ProductVariant/44884374552844',
        productDetails: [
          'Original-Beispiele für Unterweisungskonzepte',
          'Eine Vielzahl von Vorschlägen für mögliche Ausbildungssituationen',
          'Muster-Präsentationen',
          'Beispielfragen für das Fachgespräch',
          'Vorbereitung zu allen Ausbiildungsberufen',
        ],
      },
    ],
  }

  const currentVerticalCheckoutProducts = computed(() => verticalCheckoutDetailsInfo[currentVertical.value!])

  const redirectToPreviousPage = () => {
    router.go(-1)
  }

  onMounted(() => {
    if (!course.value && currentCourseId.value) {
      CourseModule.getCourse({ courseId: currentCourseId.value }).then((data) => {
        if (data.courseBundle) {
          const bundle = CourseModule.courseBundles?.find((b) => b.courseBundleId === data.courseBundle?.courseBundleId)
          bundle ? CourseModule.setCourseBundle(bundle) : undefined
        }
      })
    }
  })
</script>

<template lang="pug">
.skg-course-pre-checkout-wrapper
  LoadingSpinner(v-if='loadingData')
  .skg-course-pre-checkout(v-else)
    .back-button(@click='redirectToPreviousPage')
      img.ketch-rotate-180(:src='arrowRightIndicator')
      h5 {{ $t('previous') }}
    h3.ketch-text-primary-color {{ verticalProfession }}/-in
    h2.ketch-text-white.ketch-font-bold.ketch-text-center {{ $t('courses.preCheckout.chooseYourPackage', { vertical: prependCurrentVertical }) }}
    .package-overview.ketch-text-center
      .ketch-flex.ketch-items-center.ketch-space-x-c10(
        :key='item.text',
        v-for='item in packagePreviewDetails'
      )
        SVGRenderer(
          :has-hover='false',
          :icon='item.iconUrl',
          :stroke-color='"var(--primary-color)"',
          :width='"15px"'
        )
        h5.ketch-text-white(v-html='item.text')
    .pre-checkout-products
      PreCheckoutCourseTile(
        :key='product.productType',
        :pre-checkout-properties='product',
        :vertical='currentVertical',
        v-for='product in currentVerticalCheckoutProducts'
      )
</template>

<style scoped lang="postcss">
  .skg-course-pre-checkout-wrapper {
    @apply ketch-mt-c20;

    .skg-course-pre-checkout {
      @apply ketch-flex ketch-flex-col ketch-space-y-c20 ketch-items-center ketch-justify-center md:ketch-space-y-c30;

      .back-button {
        @apply ketch-w-full ketch-flex ketch-items-center ketch-space-x-c5 ketch-justify-start ketch-cursor-pointer;
        h5 {
          @apply ketch-text-white ketch-underline;
        }
      }

      .package-overview {
        @apply ketch-flex ketch-flex-col ketch-space-y-c8 ketch-items-center ketch-justify-center ketch-space-x-c20;
        @apply md:ketch-space-y-0 md:ketch-flex-row;
      }

      .pre-checkout-products {
        @apply ketch-grid ketch-grid-cols-1 ketch-gap-c20 md:ketch-grid-cols-3;
      }
    }
  }
</style>
