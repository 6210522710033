import { render, staticRenderFns } from "./CourseModules.vue?vue&type=template&id=6065b730&scoped=true&lang=pug"
import script from "./CourseModules.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseModules.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseModules.vue?vue&type=style&index=0&id=6065b730&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6065b730",
  null
  
)

export default component.exports