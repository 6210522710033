<template lang="pug">
.company-activity-log
  LoadingSpinner(v-if='fetchingActivityLogs')
  .activity-log-container(v-else)
    .activity-log(
      :key='log.datePerformed',
      v-for='log in activityLogs'
    )
      h5 {{ log.datePerformed }}
      .activities
        template(v-for='activity in log.activities')
          .activity.group-created(
            :key='activity.id',
            v-if='activity.activityType === "group_created"'
          )
            h5.bold {{ $t('homepage.activityLog.groupCreated') }}
            h6.group-pill(
              :style='{ backgroundColor: backgroundColor(activity.group.colorId), color: foregroundColor(activity.group.colorId) }'
            ) {{ activity.group.name }}
          .activity.invite-accepted(
            :key='activity.id',
            v-else-if='activity.activityType === "invitation_accepted"'
          )
            h5 {{ activity.email }}
            h5.bold {{ $t('homepage.activityLog.invitationAccepted') }}
          .activity.invite-sent(
            :key='activity.id',
            v-else-if='activity.activityType === "invitation_sent"'
          )
            h5 {{ activity.email }}
            h5.bold {{ $t('homepage.activityLog.invitationSent') }}
          .activity.assigned(
            :key='activity.id',
            v-else-if='activity.activityType === "assigned_to_group"'
          )
            h5 {{ activity.email }}
            .title-pill
              h5.bold {{ $t('homepage.activityLog.assignedToGroup') }}
              h6.group-pill(
                :style='{ backgroundColor: backgroundColor(activity.group.colorId), color: foregroundColor(activity.group.colorId) }'
              ) {{ activity.group.name }}
          .activity.unassigned(
            :key='activity.id',
            v-else-if='activity.activityType === "unassigned_from_group"'
          )
            h5 {{ activity.email }}
            .title-pill
              h5.bold {{ $t('homepage.activityLog.unassignedFromGroup') }}
              h6.group-pill(
                :style='{ backgroundColor: backgroundColor(activity.group.colorId), color: foregroundColor(activity.group.colorId) }'
              ) {{ activity.group.name }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { UserModule } from '@/store/modules/user'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

  const fetchingActivityLogs = ref(false)

  const activityLogs = computed(() => UserModule.activityLogs)

  const backgroundColor = (id: string) => UserModule.groupColorValue(id)?.backgroundColor
  const foregroundColor = (id: string) => UserModule.groupColorValue(id)?.foregroundColor

  fetchingActivityLogs.value = true
  UserModule.getCompanyActivityLog().then(() => {
    fetchingActivityLogs.value = false
  })
</script>

<style lang="postcss" scoped>
  .company-activity-log {
    .loading {
      @apply ketch-mx-auto;
    }
    .title-with-underline {
      @apply ketch-pb-c12;
      &:after {
        @apply ketch-bg-secondary-color;
      }
    }
    .activity-log-container {
      @apply ketch-space-y-c40;
      .activity-log {
        @apply ketch-space-y-c15;
      }
      .activities {
        @apply ketch-flex ketch-flex-col ketch-space-y-c10 ketch-relative;
        &:before {
          @apply ketch-absolute ketch-left-c5 ketch-top-c6 ketch-bottom-c4 ketch-w-c1;
          background: linear-gradient(
            to bottom,
            rgba(80, 80, 80, 0) 0%,
            #d0d0d0 8%,
            #d0d0d0 92%,
            rgba(80, 80, 80, 0) 100%
          );
          content: '';
        }
        .activity {
          @apply ketch-flex ketch-items-center ketch-relative ketch-pl-[22px];
          &:before {
            @apply ketch-absolute ketch-left-c4 ketch-w-[3px] ketch-top-c4 ketch-bottom-c2;
            @apply ketch-bg-secondary-color ketch-rounded-b-normal ketch-rounded-t-normal;
            content: '';
          }
          &.group-created {
            @apply ketch-space-x-c12;
          }
          &.assigned,
          &.unassigned,
          &.invite-sent,
          &.invite-accepted {
            @apply ketch-flex ketch-flex-col ketch-items-start;
          }
          .title-pill {
            @apply ketch-flex ketch-items-center ketch-space-x-c12;
          }
          .group-pill {
            @apply ketch-py-c1 ketch-px-c8 ketch-rounded-normal ketch-inline-block ketch-font-bold;
          }
          .bold {
            @apply ketch-font-bold;
          }
        }
      }
    }
  }
</style>
