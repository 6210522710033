<template lang="pug">
.assign-user-course-modal(data-cy='assign-course-to-user')
  h3.title-with-underline {{ $t('homepage.assignCourses') }}
  h5.info {{ $t('homepage.assignCourseInfo') }}
  .courses-wrapper
    Tabs(
      :active-tab-index='activeTabIndex',
      :tabs='tabs',
      @tab-index='activeTabIndex = $event'
    )
    keep-alive
      CompanyCourses(
        :auth0-id='user.id',
        :get-user-activity='getUserActivity',
        :grouped-courses='groupedOwnedCourses',
        :header-title='headerTitle',
        :is-purchased-course='isPurchasedCourse',
        :is-purchased-item='isPurchasedItem',
        :submit-courses='submitCompanyCourses',
        @disable-submit-c-t-a='disableSubmitCoursesCTA = $event',
        @submitting-courses='submittingCompanyCourses = $event',
        v-if='hasCompanyOwnedCourses'
      )
      ExternalCourses(
        :get-user-activity='getUserActivity',
        :grouped-courses='groupedExternalCourses',
        :header-title='headerTitle',
        :is-purchased-course='isPurchasedCourse',
        :is-purchased-item='isPurchasedItem',
        :user='user',
        v-if='externalCourses.length && isActiveTab($t("homepage.assignedCourses.externalCourses"))'
      )
  .apply(v-if='hasCompanyOwnedCourses')
    KetchUpButton.primary(
      :disabled='submittingCompanyCourses || disableSubmitCoursesCTA',
      @click.native='submitCompanyCourses = true'
    )
      LoadingSpinner(v-if='submittingCompanyCourses')
      h5(v-else) {{ $t('homepage.applyChanges') }}
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { CourseModule } from '@/store/modules/course'
  import Tabs from '@/components/common/Tabs.vue'
  import CompanyCourses from '@/components/course/CompanyCourses.vue'
  import ExternalCourses from '@/components/course/ExternalCourses.vue'
  import KetchUpButton from '@/components/common/KetchUpButton.vue'
  import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
  import CookieHelper from '@/helpers/CookieHelper'
  import useCourse from '@/composables/useCourse'
  import { UserModule } from '@/store/modules/user'
  import useI18n from '@/composables/useI18n'
  import type { PropType } from 'vue'
  import type { CourseItem, CoursePackage } from '@/services/interfaces/Course'

  defineProps({
    user: {
      type: Object as PropType<{ id: string; name: string; email: string }>,
      required: true,
    },
  })

  const { groupCourses } = useCourse()
  const { translateString } = useI18n()
  const groupedExternalCourses = ref({} as { [key: string]: (CourseItem | CoursePackage)[] })
  const groupedOwnedCourses = ref({} as { [key: string]: (CourseItem | CoursePackage)[] })
  const activeTabIndex = ref(0)
  const submitCompanyCourses = ref(false)
  const submittingCompanyCourses = ref(false)
  const disableSubmitCoursesCTA = ref(true)

  const coursesOwnedByCompany = computed(() => CourseModule.coursesOwnedByCompany)
  const coursesOwnedByCompanySize = computed(() => {
    return Object.values(groupedOwnedCourses.value).flat().length
  })

  const hasCompanyOwnedCourses = computed(
    () =>
      coursesOwnedByCompany.value.length && isActiveTab.value(translateString('homepage.assignedCourses.ownCourses')),
  )

  const externalCourses = computed(() => CourseModule.externalCourses)
  const externalCoursesSize = computed(() => {
    return Object.values(groupedExternalCourses.value).flat().length
  })

  const tabs = computed(() => {
    const _tabs = []
    if (coursesOwnedByCompany.value.length) {
      _tabs.push(
        `${translateString('homepage.assignedCourses.ownCourses')} <span>${coursesOwnedByCompanySize.value}</span>`,
      )
    }
    if (externalCourses.value.length) {
      _tabs.push(
        `${translateString('homepage.assignedCourses.externalCourses')} <span>${externalCoursesSize.value}</span>`,
      )
    }
    return _tabs
  })
  const isActiveTab = computed(() => (name: string) => tabs.value[activeTabIndex.value].startsWith(name))

  const purchasedCourseIds = CookieHelper.getCookieValue('purchased_course_id')
  purchasedCourseIds?.forEach((courseId: string) => {
    const purchasedCourse = CourseModule.memberCourses?.find((c) => c.id === courseId)
    if (purchasedCourse) {
      if (purchasedCourse.purchased) {
        CookieHelper.removeCookie('purchased_course_id')
      } else {
        purchasedCourse.purchased = true
      }
    }
  })

  groupCourses(externalCourses.value).then((data) => {
    groupedExternalCourses.value = data
  })
  groupCourses(coursesOwnedByCompany.value).then((data) => {
    groupedOwnedCourses.value = data
  })

  const isPurchasedCourse = computed(
    () => (courseId: string) => CourseModule.purchasedCourses.some((c) => c.id === courseId),
  )
  const isPurchasedBundle = computed(() => (bundle: CoursePackage) => bundle.courses.every((c) => c.purchased))
  const isPurchasedItem = computed(() => (item: CourseItem | CoursePackage) => {
    return (item as CourseItem).id
      ? isPurchasedCourse.value((item as CourseItem).id)
      : isPurchasedBundle.value(item as CoursePackage)
  })

  const headerTitle = computed(() => {
    return (key: string) => {
      if (key === 'purchased') return translateString('homepage.assignedCourses.alreadyPurchased')
      if (key === 'trial') return translateString('homepage.assignedCourses.inTrial')
      if (key === 'bundles') return translateString('homepage.assignedCourses.completePackages')
      return translateString('homepage.assignedCourses.otherTrainings')
    }
  })

  const getUserActivity = (courseId?: string) => {
    if (!courseId) return
    return UserModule.userCourseActivities?.find((c) => c.courseId === courseId)
  }
</script>

<style lang="postcss">
  .assign-user-course-modal {
    .title-with-underline {
      @apply ketch-pb-c12;
    }
    .info {
      @apply ketch-mb-c25;
    }
    .courses-wrapper {
      @apply ketch-max-h-[270px] ketch-overflow-auto ketch-mb-c20;
    }
    .apply {
      @apply ketch-flex ketch-justify-end;
      button {
        @apply ketch-w-auto;
      }
    }
  }
</style>
