import { render, staticRenderFns } from "./CourseInfoLoader.vue?vue&type=template&id=44cd480b&scoped=true&lang=pug"
import script from "./CourseInfoLoader.vue?vue&type=script&setup=true&lang=ts"
export * from "./CourseInfoLoader.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CourseInfoLoader.vue?vue&type=style&index=0&id=44cd480b&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44cd480b",
  null
  
)

export default component.exports