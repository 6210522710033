import { render, staticRenderFns } from "./SKGCoursePreCheckout.vue?vue&type=template&id=498eca5f&scoped=true&lang=pug"
import script from "./SKGCoursePreCheckout.vue?vue&type=script&setup=true&lang=ts"
export * from "./SKGCoursePreCheckout.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SKGCoursePreCheckout.vue?vue&type=style&index=0&id=498eca5f&prod&scoped=true&lang=postcss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "498eca5f",
  null
  
)

export default component.exports