import { render, staticRenderFns } from "./StartCourseForFreeModal.vue?vue&type=template&id=6d6a957a&scoped=true&lang=pug"
import script from "./StartCourseForFreeModal.vue?vue&type=script&setup=true&lang=ts"
export * from "./StartCourseForFreeModal.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StartCourseForFreeModal.vue?vue&type=style&index=0&id=6d6a957a&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6a957a",
  null
  
)

export default component.exports